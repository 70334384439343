import { Link } from '@/components/Link'
import { useToggle } from '@/hooks/use-toggle'
import { PAGES } from '@/routes'
import {
  Button,
  ButtonWithIcon,
  Modal,
  SIZE,
  SwitchField,
  Table,
} from '@blockchain-traceability-sl/tailwind-components'
import { ArrowLeftIcon } from '@heroicons/react/outline'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useCookiesConfig } from './use-cookies'
import { useLocation } from '@reach/router'

const whitelist: string[] = [PAGES.COOKIES]

export const CookieBanner = memo((): JSX.Element => {
  const { t } = useTranslation('nsCookieBanner')

  const initialFocusRef = useRef<HTMLButtonElement>(null)
  const cookiesConfig = useCookiesConfig()
  const location = useLocation()

  const [settingsEnable, toggleSettingEnable] = useToggle(false)
  const [customCookiesConfig, setCustomCookiesConfig] = useState({
    analytics: true,
    personalization: true,
    adds: true,
  })

  const handleAcceptAll = useCallback(() => {
    cookiesConfig?.saveConfig({
      mandatory: true,
      analytics: true,
      personalization: true,
      adds: true,
    })
  }, [cookiesConfig])

  const handleRefuseAll = useCallback(() => {
    cookiesConfig?.saveConfig({
      mandatory: true,
      analytics: false,
      personalization: false,
      adds: false,
    })
  }, [cookiesConfig])

  const handleConfig = useCallback(() => {
    cookiesConfig?.saveConfig({
      mandatory: true,
      ...customCookiesConfig,
    })
  }, [cookiesConfig, customCookiesConfig])

  const handleChangeCustomCookiesConfig = useCallback(
    (
      cookie: 'analytics' | 'personalization' | 'adds' | 'acceptAll' | 'refuseAll',
      checked: boolean
    ) => {
      if ((cookie === 'acceptAll' && checked) || (cookie === 'refuseAll' && !checked)) {
        setCustomCookiesConfig({
          analytics: true,
          personalization: true,
          adds: true,
        })
      } else if ((cookie === 'refuseAll' && checked) || (cookie === 'acceptAll' && !checked)) {
        setCustomCookiesConfig({
          analytics: false,
          personalization: false,
          adds: false,
        })
      } else {
        setCustomCookiesConfig({
          ...customCookiesConfig,
          [cookie]: checked,
        })
      }
    },
    [customCookiesConfig]
  )

  const show = useMemo(() => {
    return !cookiesConfig?.isConfigured && !whitelist.some(item => location.pathname.includes(item))
  }, [cookiesConfig?.isConfigured, location.pathname])

  return (
    <Modal
      show={show}
      size={!settingsEnable ? SIZE.MEDIUM : SIZE.LARGE}
      initialFocus={initialFocusRef}
    >
      <Modal.Body>
        <div className='max-w-7xl mx-auto text-center'>
          {!settingsEnable ? (
            <>
              <p>
                <Trans
                  t={t}
                  i18nKey='title'
                  components={{
                    a: <Link to={PAGES.COOKIES} className='underline text-blue-500' />,
                  }}
                />
              </p>
              <div className='mt-8 flex flex-col md:flex-row justify-center space-y-4 md:space-x-4 md:space-y-0'>
                <Button innerRef={initialFocusRef} onClick={handleAcceptAll}>
                  {t('acceptAll')}
                </Button>
                <Button color='secondary' onClick={handleRefuseAll}>
                  {t('refuseAll')}
                </Button>
                <Button color='secondary' onClick={toggleSettingEnable}>
                  {t('config')}
                </Button>
              </div>
            </>
          ) : (
            <>
              <p>{t('table.title')}</p>
              <Table
                className='my-4 text-left'
                columns={[
                  { Header: t('table.header.type').toString(), accessor: 'type' },
                  { Header: t('table.header.description').toString(), accessor: 'description' },
                  { Header: t('table.header.status').toString(), accessor: 'status' },
                ]}
                data={[
                  {
                    id: 'mandatory',
                    type: t('table.mandatory.type'),
                    description: t('table.mandatory.description'),
                    status: t('table.mandatory.status'),
                  },
                  {
                    id: 'analytics',
                    type: t('table.analytics.type'),
                    description: t('table.analytics.description'),
                    status: (
                      <SwitchField
                        id='analytics'
                        name='analytics'
                        onChange={checked => handleChangeCustomCookiesConfig('analytics', checked)}
                        checked={customCookiesConfig.analytics}
                      />
                    ),
                  },
                  {
                    id: 'personalization',
                    type: t('table.personalization.type'),
                    description: t('table.personalization.description'),
                    status: (
                      <SwitchField
                        id='personalization'
                        name='personalization'
                        onChange={checked =>
                          handleChangeCustomCookiesConfig('personalization', checked)
                        }
                        checked={customCookiesConfig.personalization}
                      />
                    ),
                  },
                  {
                    id: 'adds',
                    type: t('table.adds.type'),
                    description: t('table.adds.description'),
                    status: (
                      <SwitchField
                        id='adds'
                        name='adds'
                        onChange={checked => handleChangeCustomCookiesConfig('adds', checked)}
                        checked={customCookiesConfig.adds}
                      />
                    ),
                  },
                  {
                    id: 'acceptAll',
                    type: t('table.acceptAll.type'),
                    description: t('table.acceptAll.description'),
                    status: (
                      <SwitchField
                        id='acceptAll'
                        name='acceptAll'
                        onChange={checked => handleChangeCustomCookiesConfig('acceptAll', checked)}
                        checked={
                          customCookiesConfig.analytics &&
                          customCookiesConfig.personalization &&
                          customCookiesConfig.adds
                        }
                      />
                    ),
                  },
                  {
                    id: 'refuseAll',
                    type: t('table.refuseAll.type'),
                    description: t('table.refuseAll.description'),
                    status: (
                      <SwitchField
                        id='refuseAll'
                        name='refuseAll'
                        onChange={checked => handleChangeCustomCookiesConfig('refuseAll', checked)}
                        checked={
                          !customCookiesConfig.analytics &&
                          !customCookiesConfig.personalization &&
                          !customCookiesConfig.adds
                        }
                      />
                    ),
                  },
                ]}
                pagination={false}
              />
              <div className='flex justify-between'>
                <ButtonWithIcon
                  onClick={toggleSettingEnable}
                  color='secondary'
                  IconComponent={ArrowLeftIcon}
                >
                  {t('back')}
                </ButtonWithIcon>
                <Button onClick={handleConfig}>{t('saveConfig')}</Button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
})
