import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import { useLocation } from '@reach/router'
import { LegacyRef } from 'react'
import { getCurrentPageLanguage, getTranslatedPath } from '@/i18n/utils'

export type LinkProps<TState> = GatsbyLinkProps<TState> & {
  ref?: LegacyRef<GatsbyLink<TState>> | undefined
  query?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Link = <TState extends any = never>({
  to,
  query,
  ...props
}: LinkProps<TState>): JSX.Element => {
  const { pathname } = useLocation()

  return (
    <GatsbyLink
      {...props}
      to={`${getTranslatedPath(pathname, to)}${query ? `?${query}` : ''}`}
      lang={getCurrentPageLanguage(pathname)}
    />
  )
}
